.overview-panel-container {
  position: fixed;
  top: 160px;
  right: calc((100vw - 800px) / 2);
  z-index: 10;
  width: 480px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transform: translateX(50%);
}

.overview-panel-header {
  display: table;
  width: 100%;
  height: 64px;
  padding-left: 24px;
  border-bottom: 1px solid #e9eaee;
  border-radius: 2px;
}

.overview-panel-header-text {
  display: table-cell;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
}

.overview-initial-info {
  display: table;
  width: 100%;
  height: 168px;
  text-align: center;
}

.overview-initial-info-text {
  display: table-cell;
  color: rgba(15, 46, 74, 0.4);
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
}

.overview-wellfield-info-container {
  width: 100%;
  max-height: calc(100vh - 64px - 2 * 160px);
  padding: 24px;
  overflow: auto;
}

.wellfield-name-icon {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  background-image: url(../../assets/images/settings/wellfield-icon.svg);
  background-position: center 2px;
}

.wellfield-name-text {
  color: #0f2e4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.wellfield-desc-text {
  display: block;
  margin-top: 16px;
  margin-left: 28px;
  color: #0f2e4a;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.wellfield-desc-text.bold {
  display: inline-block;
  margin: 0;
  font-weight: bold;
}

.overview-wellfield-table-panel {
  position: relative;
  z-index: 0;
  margin-top: 12px;
  margin-left: 28px;
  padding: 14px 16px 16px 16px;
  background: #f3f4f8;
  border-radius: 2px;
}

.table-panel-caption-text {
  float: left;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.table-panel-caption-arrow {
  display: inline-block;
  float: right;
  width: 16px;
  height: 16px;
  background-image: url(../../assets/images/arrow-expandable.svg);
  background-repeat: no-repeat;
  background-position: center 4px;
}

.table-panel-caption-arrow.expanded {
  background-image: url(../../assets/images/arrow.svg);
}

.overview-wellfield-table-subtitle {
  display: block;
  margin-top: 2px;
  color: #0f2e4a;
  font-size: 14px;
  line-height: 20px;
}

.overview-wellfield-table {
  width: 100%;
  margin-top: 12px;
  background-color: #fff;
  border-radius: 2px;
  border-collapse: separate;
  border-spacing: 16px;
}

.table-caption-button {
  width: 100%;
}

.overview-wellfield-table-parameter-cell {
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.overview-wellfield-table-value-cell {
  color: #0f2e4a;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.overview-center-icon {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  background-image: url(../../assets/images/settings/center-icon.svg);
  background-position: center 2px;
}

.overview-center-icon.empty {
  background-image: none;
}

.overview-centers-container {
  margin-top: 16px;
  border-top: 1px solid #e9eaee;
  border-radius: 2px;
}

.overview-center-row {
  margin-top: 24px;
}

.overview-center-row-name-text {
  color: #0f2e4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.overview-center-row-pressure-text {
  color: #0f2e4a;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1420px) {
  .overview-panel-container {
    width: 424px;
  }
}

@media screen and (max-height: 700px) {
  .overview-panel-container {
    top: 80px;
  }

  .overview-wellfield-info-container {
    max-height: calc(100vh - 64px - 2 * 80px);
  }
}

.overview-wellfield-info-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.overview-panel-top-shadow {
  height: 64px;
}
