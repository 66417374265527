.task-settings-container {
  overflow: unset;
}

.task-settings-row {
  width: 100%;
  height: 64px;
}

.task-settings-row.checkbox-row {
  height: 20px;
  vertical-align: middle;
}

.task-settings-row.checkbox-row:not(:last-child) {
  margin-top: 24px;
}

.task-settings-row.checkbox-row:last-child {
  margin-top: 20px;
}

.task-settings-row:not(:first-child) {
  margin-top: 20px;
}

.task-settings-row-parameter-column:not(:last-child) {
  margin-right: 16px;
}

.task-settings-input-container {
  width: 160px;
}

.task-settings-row-parameter-column sup {
  font-weight: 600;
  font-size: 8px;
  vertical-align: super;
}

.task-settings-checkbox-label {
  color: #0f2e4a;
  font-size: 14px;
  line-height: 20px;
}

.task-settings-checkbox-input {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: white;
  border: 1px solid #dfe0e4;
  border-radius: 2px;
  -webkit-appearance: none;
}

.task-settings-checkbox-input:hover {
  border-color: #bfc0c9;
}

.task-settings-checkbox-input:checked {
  background-image: url(../../assets/images/checked.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.task-settings-parameter-label {
  margin-bottom: 6px;
  line-height: normal;
}
