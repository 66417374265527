.settings-container {
  position: relative;
  width: 100%;
  height: calc(max(568px, 100vh - 72px - 80px));
  padding: 0 32px 16px 32px;
}

.settings-content-container {
  max-height: calc(max(568px, 100vh - 72px - 80px) - 72px);
  margin-top: 0;
  overflow: auto;
}

.settings-title {
  color: #0f2e4a;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.settings-subtitle {
  display: block;
  margin-bottom: 12px;
  color: #0f2e4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.settings-panel {
  width: 100%;
  padding: 20px 24px 24px 24px;
  background-color: #f3f4f8;
  border-radius: 2px;
}

.settings-dropdown-container {
  width: 336px;
}

.settings-input-container {
  width: 160px;
}

.settings-dropdown-label {
  display: block;
  margin-bottom: 4px;
  color: rgba(15, 46, 74, 0.4);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.settings-container-button-panel {
  position: absolute;
  right: 32px;
  bottom: 0;
  left: 32px;
  height: 72px;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: #e9eaee;
    border-radius: 2px;
    content: '';
  }
}

.button-panel-wrapper {
  position: relative;
  display: table;
  width: 100%;
  height: 40px;
  margin-top: 16px;
  text-align: center;
}

.settings-container-prev-btn {
  position: absolute !important;
  top: 0;
  left: 0;
}

.settings-container-next-btn {
  position: absolute !important;
  top: 0;
  right: 0;
}

.settings-container-dot-icon circle {
  fill: #e9eaee;
}

.settings-container-dot-icon.highlighted circle {
  fill: #006cb1;
}

.settings-container-dot-icon:not(:last-child) {
  margin-right: 16px;
}

.settings-container-dot-icons-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.settings-tooltip {
  position: absolute;
  top: 44px;
  left: 0;
  height: 36px;
  padding: 8px 12px;
  background-color: rgba(13, 26, 51, 0.9);
  border-radius: 2px;
  opacity: 0;
}

.task-settings-input-tooltip {
  z-index: -1;
}

.task-settings-input-tooltip.empty-value {
  width: 203px;
}

.task-settings-input-tooltip.not-number {
  width: 182px;
}

.droparea-tooltip {
  top: 196px;
}

.wellfield-settings-drop-area.small-size .droparea-tooltip {
  top: 60px;
  z-index: 1;
}

.uploaded-file-tooltip {
  top: 60px;
  z-index: 1;
}

.uploaded-file-tooltip.top {
  top: -40px;
}

.gathering-center-pressure-tooltip {
  z-index: -1;
}

.gathering-center-pressure-tooltip.top {
  top: -40px;
}

.gathering-center-pressure-tooltip.empty-value {
  width: 167px;
}

.gathering-center-pressure-tooltip.not-number {
  width: 182px;
}

.settings-tooltip-text {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.settings-dropdown-container:hover .settings-tooltip.error,
.settings-input-container:hover .settings-tooltip.error,
.task-settings-input-container:hover .settings-tooltip.error,
.wellfield-settings-drop-area:hover .settings-tooltip.error,
.mode-table-file-panel:hover .settings-tooltip.error {
  opacity: 1;
  -webkit-transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}

.task-settings-input-container:hover .task-settings-input-tooltip.error,
.settings-input-container:hover .gathering-center-pressure-tooltip.error {
  z-index: 10;
}

.settings-top-row {
  margin: 24px 0 24px 32px;
}

.top-shadow {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 24px;
  box-shadow: 0 4px 24px rgba(23, 27, 61, 0.08);
  opacity: 0;
  transition: all 0.25s ease;
}

.bottom-shadow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: 32px;
  box-shadow: 0 -4px 24px rgba(23, 27, 61, 0.08);
  opacity: 0;
  transition: all 0.25s ease;
}

.scrollable-wrapper.scrolled {
  .bottom-shadow,
  .top-shadow {
    z-index: 1;
    opacity: 1;
  }
}

.settings-bottom-shadow {
  height: 15px;
  box-shadow: 0 -6px 6px -6px rgba(23, 27, 61, 0.08);
}
