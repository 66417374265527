.wellfield-settings-container {
  overflow: unset;
}

.files-label {
  margin-top: 20px;
  margin-bottom: 8px;
}

.wellfield-settings-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.wellfield-settings-drop-area {
  display: table;
  width: 100%;
  height: 192px;
  background: linear-gradient(0deg, rgba(4, 106, 210, 0.07), rgba(4, 106, 210, 0.07)), #fff;
  border: 1px dashed rgba(4, 106, 210, 0.6);
  border-radius: 4px;
  cursor: pointer;
}

.wellfield-settings-drop-area.small-size {
  height: 56px;
  margin-bottom: 16px;
}

.wellfield-settings-drop-area:hover {
  background: linear-gradient(0deg, rgba(4, 106, 210, 0.12), rgba(4, 106, 210, 0.12)), #fff;
  border: 1px dashed rgba(4, 106, 210, 0.8);
}

.wellfield-settings-drop-area.dragging {
  background: linear-gradient(0deg, rgba(4, 106, 210, 0.16), rgba(4, 106, 210, 0.16)), #fff;
  border: 1px solid rgba(4, 106, 210, 0.6);
}

.wellfield-settings-drop-area.error {
  background: linear-gradient(0deg, rgba(240, 17, 94, 0.07), rgba(240, 17, 94, 0.07)), #fff;
  border: 1px dashed rgba(240, 17, 94, 0.6);
}

.drop-area-button-wrapper {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.drop-area-button-text {
  color: #006cb1;
  font-size: 16px;
  line-height: 20px;
}

.drop-area-button-text.bold {
  font: 500 16px 'futura-pt', sans-serif;
}

.wellfield-settings-drop-area.error .drop-area-button-text {
  color: #f0115e;
}

.mode-table-file-panel {
  display: table;
  width: 100%;
  height: 56px;
  margin-top: 8px;
  padding: 0 19px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
}

.mode-table-file-panel.error {
  border: 1px solid #f0115e;
}

.mode-table-file-panel.error .file-panel-content-text {
  color: #f0115e;
}

.file-panel-content {
  position: relative;
  display: table-cell;
  width: 100%;
  height: 20px;
  vertical-align: middle;
}

.file-panel-content-text {
  color: #0f2e4a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.file-panel-excel-icon {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background-image: url(../../assets/images/settings/excel-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.file-panel-delete-icon {
  position: absolute;
  top: 18px;
  right: 0;
  width: 20px;
  height: 20px;
  background-image: url(../../assets/images/settings/delete-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.wellfield-settings-errors-list-container {
  margin: 16px 0;
}

.wellfield-settings-error-row {
  margin-bottom: 8px;
  padding: 16px 16px 16px 48px;
  border: 1px solid #dfe0e4;
  border-radius: 2px;
}

.error-row-image {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 20px;
  height: 20px;
  background-image: url(../../assets/images/settings/error-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.error-row-text {
  display: block;
  color: #0f2e4a;
  font-size: 14px;
  line-height: 20px;
}

.error-row-text.error-text {
  font-weight: 500;
}
